import React, {Component} from 'react';
import * as ServerResponseHandler from '../../../components/Framework/ServerResponseHandler';
import * as Util from '../../../components/Framework/Util';
import {formatDateToUtc} from '../../../components/Framework/Util';
import {SearchBar} from '../../../components/Framework/SearchBar';
import {toPdfPayRoll} from "../../../components/Framework/HtmlToPdf";
import {Button, Card, CardBody, CardHeader, Container, Modal, ModalFooter} from "reactstrap";
import RunPayrollTable from "../../../components/DataTables/RunPayrollTable";
import PayrollReport from "../../../components/ReportTemplates/PayrollReport/PayrollReport";
import {toast} from 'react-toastify';

const moment = require('moment-timezone');

class RunPayroll extends Component {
    constructor(props) {
        super(props);
        this.userTenant = Util.getUserTenant();
        this.state = {
            data: [],
            csvData: [],
            loading: false,
            selectedPayPeriod: {},
            isOpen: false,
        };
        this.getTotalValues = this.getTotalValues.bind(this);
        this.getTotalCurrencyValues = this.getTotalCurrencyValues.bind(this);
        this.getPtoDuration = this.getPtoDuration.bind(this);
        this.getPtoPay = this.getPtoPay.bind(this);
        this.loadData = this.loadData.bind(this);
        this.getPdfProperties = this.getPdfProperties.bind(this);
        this.savePdf = this.savePdf.bind(this);
        this.getTableColumns = this.getTableColumns.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.print = this.print.bind(this);
    }

    getTableColumns() {
        return [
            {displayName: 'First Name', hideColumn: false},
            {displayName: 'Last Name', hideColumn: false},
            {displayName: 'Sick Hours', hideColumn: false},
            {displayName: 'Vacation Hours', hideColumn: false},
            {displayName: 'Holiday Hours', hideColumn: false},
            {displayName: 'Regular Hours', hideColumn: false},
            {displayName: 'Overtime', hideColumn: false},
            {displayName: 'Total', hideColumn: false},
            {displayName: 'Pay', hideColumn: false}
        ];
    }

    pdfColumnHeaders() {
        let pdfColumns = [
            {title: '#', dataKey: 'id', showColumn: true},
            {title: 'First Name', dataKey: 'firstName', showColumn: true},
            {title: 'Last Name', dataKey: 'lastName', showColumn: true},
            {title: 'Sick Hours', dataKey: 'sickHours', showColumn: true},
            {title: 'Vacation Hours', dataKey: 'vacationHours', showColumn: true},
            {title: 'Holiday Hours', dataKey: 'holidayHours', showColumn: true},
            {title: 'Regular Hours', dataKey: 'regularHours', showColumn: true},
            {title: 'Overtime', dataKey: 'overtime', showColumn: true},
            {title: 'Total', dataKey: 'total', showColumn: true},
            {title: 'Pay', dataKey: 'pay', showColumn: true}
        ]

        return pdfColumns;
    }

    componentDidMount() {
        this.loadData();
    }

    getTotalValues() {
        let totalHoursSummary = {
            totalRegularHours: 0,
            totalOvertimeHours: 0,
            totalPtoHours: 0,
            totalHours: 0
        };

        for (let i = 0; i < this.state.data.length; i++) {
            totalHoursSummary.totalRegularHours += +this.state.data[i].calculatedRegularDuration;
            totalHoursSummary.totalOvertimeHours += +this.state.data[i].calculatedOvertimeDuration;
            totalHoursSummary.totalPtoHours += this.getPtoDuration(this.state.data[i].timeOffList);
            // console.log('data item', this.state.data[i]);
            console.log(totalHoursSummary);
        }
        totalHoursSummary.totalHours = totalHoursSummary.totalRegularHours + totalHoursSummary.totalOvertimeHours + totalHoursSummary.totalPtoHours;
        return totalHoursSummary;
    }

    getTotalCurrencyValues() {
        let totalRegular = 0;
        let totalOvertime = 0;
        let totalPto = 0;

        for (let i = 0; i < this.state.data.length; i++) {
            totalRegular += +this.state.data[i].calculatedRegularPay;
            totalOvertime += +this.state.data[i].calculatedOvertimePay;
            totalPto += this.getPtoPay(this.state.data[i].timeOffList);
        }

        let total = totalRegular + totalOvertime + totalPto;

        return (
            <div className='font-weight-bold'>
                {Util.formatCurrency(totalPto, true)}<br/>
                {Util.formatCurrency(totalRegular, true)}<br/>
                {Util.formatCurrency(totalOvertime, true)}<br/>
                {Util.formatCurrency(total, true)}<br/>
            </div>
        )
    }

    getPtoDuration(row) {
        let totalPto = 0;
        for (const request in row) {
            totalPto += +row[request].hours
        }
        return totalPto * 3600;
    }

    getPtoPay(row) {
        let ptoPay = 0;
        for (const request in row) {
            ptoPay += +row[request].payAmount;
        }

        return ptoPay;
    }

    loadData(search = null) {
        this.setState({loading: true});

        if (search && search.payPeriodRule) {
            if (moment().isAfter(search.payPeriodRule.nextAccrualDate)) {
                this.approvePayroll(search.payPeriodRule.id);
            }
        }

        let weekStartDay;
        fetch(Util.apiUrl(`pay_period_rule`), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then((json) => {
                // console.log('json:', json);
                if (json.resultList && json.resultList.length > 0) {
                    json.resultList.forEach(rule => {
                        if (rule.default === true) {
                            weekStartDay = rule.weekStartDay
                        }
                    });
                }


                fetch(Util.apiUrl(`reports/pay-periods`), {credentials: 'include'})
                    .then(response => ServerResponseHandler.getResponse(response))
                    .then(json => {
                        if (search === null) {
                            search = {
                                startDate: formatDateToUtc(moment(json.current.startDate).startOf('day')),
                                endDate: formatDateToUtc(moment(json.current.endDate).endOf('day')),
                                workWeekStartDate: Util.calculateDayOfWorkWeekSearch(json.current.startDate, weekStartDay),
                            };
                            this.setState({selectedPayPeriod: search});
                        } else {
                            this.setState({selectedPayPeriod: search});
                            search.workWeekStartDate = Util.calculateDayOfWorkWeekSearch(this.state.selectedPayPeriod.startDate, weekStartDay);
                        }

                        if (!Util.isAdmin()) {
                            search.departmentId = this.userTenant.departmentId;
                        }

                        search.ptoStatus = 'Approved';
                        if (search.payPeriodRule) {
                            search.payPeriodRuleId = search.payPeriodRule.id;
                        }

                        let url = Util.apiSearchUrl(`reports/time-cards`, search);
                        url += "&timezone=" + moment.tz.guess();

                        fetch(url, {credentials: 'include'})
                            .then(response => ServerResponseHandler.getResponse(response))
                            .then(json => {
                                // console.log('fetched data', json);
                                this.setState({data: json});
                                this.getPdfProperties();
                            })
                            .catch(error => ServerResponseHandler.handleError(error))


                        fetch(Util.apiSearchUrl(`reports/user-time-logs`, search), {credentials: 'include'})
                            .then(response => ServerResponseHandler.getResponse(response))
                            .then(json => {
                                json = json.map(x => {
                                    return {
                                        ...x,
                                        startDateTime: Util.basicDateFormatter(x.startDateTime, true),
                                        endDateTime: Util.basicDateFormatter(x.endDateTime, true),
                                        breakStartDateTime: x.breakStartDateTime ? Util.basicDateFormatter(x.breakStartDateTime, true) : x.breakStartDateTime,
                                        breakEndDateTime: x.breakEndDateTime ? Util.basicDateFormatter(x.breakEndDateTime, true) : x.breakEndDateTime
                                    }
                                });
                                json = this.calculateTotalTime(json);
                                this.setState({csvData: json});
                            })
                            .catch(error => ServerResponseHandler.handleError(error))
                    })
                    .catch(error => ServerResponseHandler.handleError(error))
                    .finally(() => this.setState({loading: false}));
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    calculateTotalTime(json) {
        for (let element of json) {
            let totalTimeMs = Math.abs(new Date(element.endDateTime) - new Date(element.startDateTime));
            let breakTimeMs = Math.abs(new Date(element.breakEndDateTime) - new Date(element.breakStartDateTime));

            element.totalTime = this.msToTime(totalTimeMs - breakTimeMs)
        }

        return json;
    }

    msToTime(duration) {
        let milliseconds = Math.floor((duration % 1000) / 100),
            seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;

        return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
    }

    approvePayroll(payPeriodRuleId) {
        const params = {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json;charset=UTF-8'}
        }

        let weekStartDay;
        fetch(Util.apiUrl(`pay_period_rule`), {credentials: 'include'})
            .then(response => ServerResponseHandler.getResponse(response))
            .then((json) => {
                if (json.resultList && json.resultList.length > 0) {
                    json.resultList.forEach(rule => {
                        if (rule.default === true) {
                            weekStartDay = rule.weekStartDay
                        }
                    });
                }


                fetch(Util.apiUrl(`reports/pay-periods/${payPeriodRuleId}`), {credentials: 'include'})
                    .then(response => ServerResponseHandler.getResponse(response))
                    .then(json => {
                        const search = {
                            payPeriodRuleId: payPeriodRuleId,
                            startDate: formatDateToUtc(moment(json.periodList[1].startDate).startOf('day')),
                            endDate: formatDateToUtc(moment(json.periodList[1].endDate).endOf('day')),
                            workWeekStartDate: Util.calculateDayOfWorkWeekSearch(json.periodList[1].startDate, weekStartDay),
                        };

                        let url = Util.apiSearchUrl(`payroll`, search);
                        url += "&timezone=" + moment.tz.guess();

                        fetch(url, params)
                            .then(response => ServerResponseHandler.getResponse(response))
                            .then(json => {
                                if (json.errorMessage) {
                                    toast.error(json.errorMessage, {position: 'bottom-right'});
                                    return;
                                }
                                toast.success('PTO accrual was successful.', {position: 'bottom-right'});
                            })
                            .catch(error => ServerResponseHandler.handleError(error));
                    })
                    .catch(error => ServerResponseHandler.handleError(error))
                    .finally(() => this.setState({loading: false}));
            })
            .catch(error => ServerResponseHandler.handleError(error));
    }

    getPdfProperties() {
        const self = this;
        let totalOvertimeDuration = 0;
        let totalPtoDuration = 0;
        let totalDuration = 0;

        let totalRegularPay = 0;
        let totalOvertimePay = 0;
        let totalPtoPay = 0;

        const pdfRowData = [];
        self.state.data.forEach((entry, index) => {
            totalDuration += +entry.calculatedRegularDuration + self.getPtoDuration(entry.timeOffList) + +entry.calculatedOvertimeDuration;
            totalOvertimeDuration += Number(entry.calculatedOvertimeDuration);
            totalPtoDuration += self.getPtoDuration(entry.timeOffList);

            totalRegularPay += +entry.calculatedRegularPay;
            totalOvertimePay += +entry.calculatedOvertimePay;
            totalPtoPay += self.getPtoPay(entry.timeOffList);
            let ptoHoursObj = Util.organizePtoHours(entry.timeOffList);
            let regularHours = entry.calculatedRegularDuration;
            let pay = +entry.calculatedOvertimePay + +entry.calculatedRegularPay + this.getPtoPay(entry.timeOffList);
            pdfRowData.push(
                {
                    'id': index + 1,
                    'firstName': entry.firstName,
                    'lastName': entry.lastName,
                    'sickHours': Util.formatTimeFromSeconds(ptoHoursObj.sickHours),
                    'vacationHours': Util.formatTimeFromSeconds(ptoHoursObj.vacationHours),
                    'holidayHours': Util.formatTimeFromSeconds(ptoHoursObj.holidayHours),
                    'regularHours': Util.formatTimeFromSeconds(regularHours),
                    'overtime': Util.formatTimeFromSeconds(entry.calculatedOvertimeDuration),
                    'total': Util.formatTimeFromSeconds(+entry.calculatedRegularDuration + +entry.calculatedOvertimeDuration + self.getPtoDuration(entry.timeOffList)),
                    'pay': Util.formatCurrency(pay, true),
                }
            );
        });

        let totalPay = 0;
        totalPay += totalRegularPay + totalOvertimePay + totalPtoPay;
        const pdfTotalData = {
            totalPtoHours: Util.formatTimeFromSeconds(totalPtoDuration),
            totalPtoCurrency: Util.formatCurrency(totalPtoPay, true),
            totalRegularHours: Util.formatTimeFromSeconds(totalDuration - totalOvertimeDuration - totalPtoDuration),
            totalRegularCurrency: Util.formatCurrency(totalRegularPay, true),
            totalOvertimeHours: Util.formatTimeFromSeconds(totalOvertimeDuration),
            totalOvertimeCurrency: Util.formatCurrency(totalOvertimePay, true),
            totalHours: Util.formatTimeFromSeconds(totalDuration),
            totalCurrency: Util.formatCurrency(totalPay, true),
        };

        const pdfTitle = 'Payroll Summary (' +
            Util.basicDateFormatter(self.state.selectedPayPeriod.startDate)
            + ' - ' + Util.basicDateFormatter(self.state.selectedPayPeriod.endDate) + ')';

        self.setState({
            pdfColumns: [
                {title: '#', dataKey: 'id'},
                {title: 'First Name', dataKey: 'firstName'},
                {title: 'Last Name', dataKey: 'lastName'},
                {title: 'Sick Hours', dataKey: 'sickHours'},
                {title: 'Vacation Hours', dataKey: 'vacationHours'},
                {title: 'Holiday Hours', dataKey: 'holidayHours'},
                {title: 'Regular Hours', dataKey: 'regularHours'},
                {title: 'Overtime', dataKey: 'overtime'},
                {title: 'Total', dataKey: 'total'},
                {title: 'Pay', dataKey: 'pay'},
            ],
            pdfRowData: pdfRowData,
            pdfTotalData: pdfTotalData,
            pdfTitle: pdfTitle
        });
    }

    savePdf() {
        toPdfPayRoll(this.state.pdfColumns, this.state.pdfRowData, this.state.pdfTotalData, this.state.pdfTitle, 'payrollReport', 'l');
    }

    print() {
        this.setState({isOpen: false});
        setTimeout(() => {
            window.print()
        });
    }

    toggleModal() {
        this.setState({isOpen: !this.state.isOpen})
    }


    render() {
        const csvProperties = {
            //Note that the csv does not resemble the Payroll report, it was designed with these fields for a customer and is intended to be uploaded to a payroll software in this form
            csvHeader: [
                {label: 'Employee External ID', key: 'userId'},
                {label: 'Employee Name', key: 'fullUserName'},
                {label: 'Start Date Time', key: 'startDateTime'},
                {label: 'End Date Time', key: 'endDateTime'},
                {label: 'Break Start Date Time', key: 'breakStartDateTime'},
                {label: 'Break End Date Time', key: 'breakEndDateTime'},
                {label: 'Total Time', key: 'totalTime'}
            ],
            data: this.state.csvData,
            csvFilename: 'Payroll.csv',
        };

        const buttons = {
            generateReport: true,
            approvePayroll: {
                onClick: () => this.props.history.push("/manage/time-card-approval")
            },
            add: false,
            download: csvProperties,
            toggleModal: this.toggleModal,
            loading: this.state.loading
        };

        return (
            <div className='animated fadeIn'>
                <Container fluid>
                    <Modal isOpen={this.state.isOpen} toggle={this.toggleModal} className="modal-lg dont-print">
                        <PayrollReport className={'dont-print'}
                                       title={this.state.pdfTitle}
                                       pdfColumnHeaders={this.pdfColumnHeaders()}
                                       pdfData={this.state.pdfRowData}
                                       totals={this.state.pdfTotalData}
                        />
                        <ModalFooter>
                            <Button color="success" onClick={() => this.print()}>Print Report</Button>{' '}
                            <Button color="secondary" onClick={() => this.toggleModal()}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Card className="col-lg-12 mt-3 card-accent-primary dont-print">
                        <CardHeader>
                            <SearchBar
                                buttons={buttons}
                                onSearch={this.loadData}
                                includeSearchLabel={true}
                                includePayPeriods={true}
                            />
                        </CardHeader>
                        <CardBody>
                            <RunPayrollTable
                                data={this.state.pdfRowData}
                                getTableColumns={this.getTableColumns}
                                getPtoPay={this.getPtoPay}
                                getPtoDuration={this.getPtoDuration}
                                getTotalValues={this.getTotalValues()}
                            />
                        </CardBody>
                    </Card>
                    <div className={'print allowMultiplePages addPadding'}>
                        <PayrollReport
                            title={this.state.pdfTitle}
                            pdfColumnHeaders={this.pdfColumnHeaders()}
                            pdfData={this.state.pdfRowData}
                            totals={this.state.pdfTotalData}
                        />
                    </div>
                </Container>
            </div>
        )
    }
}

export default RunPayroll;